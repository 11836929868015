export const objectToQueryString = (params) => {
  const searchParams = new URLSearchParams(params);
  return searchParams.toString();
};

export const percentageStringToNumber = (percentageString) => {
  const number = parseFloat(percentageString.replace("%", ""));
  if (isNaN(number)) {
    console.error("Invalid percentage string:", percentageString);
    return 0;
  }
  return number / 100;
};

export const getTokenImgFromApplication = (application) => {
  if (application?.logo && application?.logo.includes("brc100.svg")) {
    return require("@/assets/token/brc100.svg");
  } else if (application?.logo && application?.logo.includes("http")) {
    return application?.logo;
  } else {
    return false;
  }
};

export const debounce = (func, delay) => {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
