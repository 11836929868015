import axios from "axios";
import { btcPriceUrls, baseApiUrl } from "@/common/config";

export default {
  async fetchAndSetBtcPrice({ commit }) {
    try {
      const response = await axios.get(btcPriceUrls[0]);
      commit("SET_BTC_PRICE", response.data.USD.last);
    } catch (error) {
      console.error("Error fetching data:", error);
      try {
        const res = await axios.get(btcPriceUrls[1]);
        commit("SET_BTC_PRICE", res.data.bitcoin.usd);
      } catch (error) {
        const defaultBTCPrice = "41000";
        commit("SET_BTC_PRICE", defaultBTCPrice);
      }
    }
  },
  async fetchAndSetBrc100Price({ commit }) {
    const response = await axios.get(
      baseApiUrl + "/marketplace/token?ticker=brc100"
    );
    commit("SET_BRC100_PRICE", response.data.data?.floorPrice);
  },
};
