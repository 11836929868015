<template>
  <div id="app">
    <div id="loader-wrapper" v-if="fullLoading">
      <img src="@/assets/icons/swap-loading.svg" />
    </div>
    <common-header />
    <div
      id="main"
      class="main"
      :style="{
        height: appInfo.hasScrollbar
          ? 'calc(100vh - 70px)'
          : 'calc(100vh - 180px)',
      }"
    >
      <router-view v-if="isShow" />
      <common-footer />
    </div>
  </div>
</template>

<script>
import CommonHeader from "./components/CommonHeader.vue";
import CommonFooter from "./components/CommonFooter.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    CommonHeader,
    CommonFooter,
  },
  data() {
    return {
      loading: true,
      isShow: true,
      appInfo: {
        isMobile: document.documentElement.clientWidth < 1000,
        hasScrollbar: false,
      },
    };
  },
  provide() {
    return {
      reload: this.reload,
      appInfo: this.appInfo,
    };
  },

  computed: {
    ...mapState(["fullLoading"]),
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.appInfo.isMobile = document.documentElement.clientWidth < 1000;
      })();
    };

    const element = document.querySelector(".main");
    const observer = new MutationObserver(() => {
      this.appInfo.hasScrollbar = element.clientHeight !== element.scrollHeight;
    });

    const config = {
      childList: true,
      subtree: true,
    };

    observer.observe(element, config);

    this.$store.dispatch("fetchAndSetBtcPrice");
    this.$store.dispatch("fetchAndSetBrc100Price");
  },
  updated() {
    setTimeout(() => {
      if (this.$route.path !== "/pools") {
        this.$store.commit("SET_FULL_LOADING", false);
      }
    }, 500);
  },
  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  margin-top: 70px;
  background-color: #202020;
  overflow: auto;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #202020;
}
#loader-wrapper img {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
