<template>
  <el-dialog
    title="Connect Wallet"
    center
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="400px"
    class="connectDialog"
  >
    <div class="dialog-content">
      <div class="walletItem" @click="handleConnect('okx_wallet')">
        <img src="@/assets/okx.png" />
        <span :class="activeLoad === 'okx_wallet' ? 'active' : ''"
          >OKX Wallet</span
        >
        <i class="el-icon-loading" v-if="activeLoad === 'okx_wallet'"></i>
      </div>
      <div class="walletItem" @click="handleConnect('unisat_wallet')">
        <img src="@/assets/unisat.png" />
        <span :class="activeLoad === 'unisat_wallet' ? 'active' : ''"
          >Unisat Wallet</span
        >
        <i class="el-icon-loading" v-if="activeLoad === 'unisat_wallet'"></i>
      </div>
      <!-- <div class="walletItem" @click="handleConnect('xverse_wallet')">
        <img src="@/assets/Xverse.svg" />
        <span :class="activeLoad === 'xverse_wallet' ? 'active' : ''"
          >Xverse Wallet</span
        >
        <i class="el-icon-loading" v-if="activeLoad === 'xverse_wallet'"></i>
      </div> -->
    </div>
  </el-dialog>
</template>

<script>
import { Notification } from "element-ui";

export default {
  name: "ConnectWallet",
  data() {
    return {
      dialogVisible: false,
      activeLoad: "",
    };
  },
  inject: ["appInfo"],

  methods: {
    open() {
      this.activeLoad = "";
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    refuseConnect() {
      this.activeLoad = "";
    },
    handleConnect(type) {
      if (type === "unisat_wallet") {
        if (!window.unisat) {
          Notification({
            message: "Please install Unisat wallet first.",
            type: "warning",
          });
          return;
        }
      }
      if (type === "okx_wallet") {
        if (!window.okxwallet) {
          Notification({
            message: "Please install OKX wallet first.",
            type: "warning",
          });
          return;
        }
      }
      this.activeLoad = type;
      this.$emit("select-wallet", type);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  background: #2f2f2f !important;
}
.dialog-content {
  min-height: auto;
  background: #2f2f2f;
  padding: 0 !important;
  .walletItem {
    height: 70px;
    background: #201f1f;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    &:first-of-type {
      margin-bottom: 20px;
    }
    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    span {
      flex: 1;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    &:hover {
      span {
        color: #ff8f00;
      }
    }
    .active {
      color: #ff8f00;
    }
    .el-icon-loading {
      font-size: 18px;
      color: #7e7e7e;
    }
  }
}
</style>
