import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

const state = {
  btcPrice: "",
  brc100Price: "",
  userAddress: "",
  userPublicKey: "",
  fullLoading: true,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
});
