<template>
  <div
    class="footer-container"
    :style="{ position: appInfo.hasScrollbar ? 'static' : 'fixed' }"
  >
    <div class="logo">
      <img src="@/assets/icons/logo.svg" />
      <div>©2024 100Swap.io</div>
    </div>
    <div class="link">
      <a href="https://t.me/BRC100Developers" target="_blank">
        <img class="telegram" src="@/assets/icons/telegram.svg" />
      </a>
      <a href="https://x.com/100Swap_io" target="_blank">
        <img class="twitterX" src="@/assets/icons/twitterX.svg" />
      </a>
      <a href="https://docs.100swap.io/" target="_blank">
        <img class="docs" src="@/assets/icons/Docs.svg" />
      </a>
      <a class="last" href="mailto:contact@100swap.io" target="_blank">
        <img class="twitterX" src="@/assets/icons/icon_email.svg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasScrollbar: false,
    };
  },
  inject: ["appInfo"],
  methods: {},
};
</script>

<style lang="less" scoped>
.footer-container {
  padding: 0 17%;
  height: 110px;
  background: #2a2a2a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0;
  .logo {
    font-size: 16px;
    color: #fff;
    line-height: 10px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .link {
    display: flex;
    justify-content: space-between;
    a {
      margin-right: 25px;
    }
    .last {
      margin-right: 0;
    }
    .telegram {
      width: auto;
      height: 29px;
    }
    .docs {
      width: auto;
      height: 28px;
    }
    .twitterX {
      width: auto;
      height: 29px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .footer-container {
    padding: 0 6%;
    height: 110px;
    background: #2a2a2a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      font-size: 18px;
      color: #fff;
      img {
        width: 40px;
        height: auto;
        padding-bottom: 10px;
      }
      div {
        font-size: 16px;
      }
    }
    .link {
      a {
        margin-right: 20px;
      }
      .last {
        margin-right: 0;
      }
      .telegram {
        width: 26px;
        height: auto;
      }
      .twitterX {
        width: 26px;
        height: auto;
      }
    }
  }
}
</style>
