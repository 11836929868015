import BigNumber from "bignumber.js";

// nodata filter
export const noDataFilter = (value, placeholder = "--", zeroPlaceholder) => {
  if (value === 0 || value === "0") {
    return zeroPlaceholder ? zeroPlaceholder : value;
  }
  return value === undefined ||
    isNaN(value) ||
    value === null ||
    value === "" ||
    value === "NaN"
    ? placeholder
    : value;
};

// bignumber filter
export const moneyFilter = (value, fixedNumber = 2, short) => {
  let number = Number(value);
  if (isNaN(number) || value === "--") {
    return value;
  }
  if (number > 100) {
    if (fixedNumber) {
      number = Number(number.toFixed(fixedNumber));
    }
    let unit = "";
    if (short && short === true) {
      const maxValue = 1000000000;
      if (number >= maxValue) {
        number = Number((number / maxValue).toFixed(1));
        unit = "B";
      }
    }
    if (number > 1000) {
      return number.toLocaleString("en-US") + unit;
    }
    return number + unit;
  } else {
    if (fixedNumber) {
      return Number(number.toFixed(fixedNumber));
    }
    return Number(number.toFixed(4));
  }
};

// address filter
export const addressFilter = (address, startLength = 7, endLength = 4) => {
  if (typeof address !== "string" || address.length < startLength + endLength) {
    return address;
  }
  const frontPart = address.slice(0, startLength);
  const endPart = address.slice(-endLength);
  return `${frontPart}...${endPart}`;
};

export const addressFilterMobile = (
  address,
  startLength = 7,
  endLength = 4
) => {
  if (typeof address !== "string" || address.length < startLength + endLength) {
    return address;
  }
  if (document.documentElement.clientWidth > 992 || address === "--") {
    return address;
  }
  const frontPart = address.slice(0, startLength);
  const endPart = address.slice(-endLength);
  return `${frontPart}...${endPart}`;
};

// txHash filter
export const txHashFilter = (hash, startLength = 10, endLength = 5) => {
  if (typeof hash !== "string" || hash.length < startLength + endLength) {
    return hash;
  }
  const frontPart = hash.slice(0, startLength);
  const endPart = hash.slice(-endLength);
  return `${frontPart}...${endPart}`;
};

// timestamp filter
export const dateFilter = (timestamp, isShowYear) => {
  var date = new Date(timestamp);
  var YY = date.getFullYear() + "-";
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var mm =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  if (isShowYear) {
    return YY + MM + DD + " " + hh + mm + ss;
  }
  return MM + DD + " " + hh + mm + ss;
};

export const removeTrailingZerosFilter = (numberString) => {
  if (numberString === "--") return "--";
  BigNumber.config({
    DECIMAL_PLACES: 30,
    ROUNDING_MODE: BigNumber.ROUND_DOWN,
    EXPONENTIAL_AT: 19,
  });
  return new BigNumber(numberString).toFixed();
};

export const bignumberToFixedMobile = (value, number = 4) => {
  if (document.documentElement.clientWidth > 992 || value === "--")
    return value;
  let num = new BigNumber(value);
  return Number(num.toFixed(number, BigNumber.ROUND_DOWN).toString());
};

export const bignumberToFixed = (value, number = 4) => {
  if (value === "--") return value;
  let num = new BigNumber(value);
  return Number(num.toFixed(number, BigNumber.ROUND_DOWN).toString());
};

export const toUsdt = (value, floor, btcPrice, toFixedNumber = 4) => {
  if (value === 0 || value === "0" || !value) {
    return 0;
  }
  if (
    isNaN(Number(value)) ||
    isNaN(Number(floor)) ||
    !floor ||
    (!value && value !== 0 && value !== "0")
  ) {
    return "--";
  }
  return Number(
    new BigNumber(value)
      .multipliedBy(new BigNumber(floor))
      .dividedBy(new BigNumber(100000000))
      .multipliedBy(btcPrice)
      // .toFixed(toFixedNumber, BigNumber.ROUND_DOWN)
      .toString()
  ).toLocaleString("en-US", { maximumFractionDigits: toFixedNumber });
};

export const satsToUsdt = (value, btcPrice, toFixedNumber = 4) => {
  if (isNaN(Number(value)) || !value) {
    return value;
  }
  return Number(
    new BigNumber(value)
      .dividedBy(new BigNumber(100000000))
      .multipliedBy(btcPrice)
      .toString()
  ).toLocaleString("en-US", { maximumFractionDigits: toFixedNumber });
};

export const amountToUsdt = (value, priceUsd, toFixedNumber = 4) => {
  if (value === 0 || value === "0" || !value) {
    return 0;
  }
  if (
    isNaN(Number(value)) ||
    isNaN(Number(priceUsd)) ||
    !priceUsd ||
    (!value && value !== 0 && value !== "0")
  ) {
    return "--";
  }
  return Number(
    new BigNumber(value).multipliedBy(priceUsd).toString()
  ).toLocaleString("en-US", { maximumFractionDigits: toFixedNumber });
};

export const zeroFilter = (value, placeholder = "--") => {
  if (value === 0 || value === "0") {
    return placeholder;
  } else {
    return value;
  }
};


export const smallFilter = (value) => {
  if (value === '--') return value;
  if (parseFloat(value) === 0) return 0;

  value = parseFloat(value).toFixed(20);

  if (value.includes('.')) {
    value = value.toString().split('.')[1].split('');
    let targetIndex = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '0' && value[i + 1] !== '0') {
        targetIndex = i + 1;
        break;
      }
    }

    return `0.0{${targetIndex}}${value
      .slice(targetIndex, targetIndex + 4)
      .join('')}`;
  }
  return value;
};

export const bigNumberForBeautyFilter = (value) => {
  // return value
  if (isNaN(Number(value)) || !value) {
    return value;
  } else {
    if (new BigNumber(value).comparedTo(new BigNumber(1000000000)) === 1) {
      let val = new BigNumber(value).dividedBy(new BigNumber(1000000000)).toString()
      if(val.includes('.')) {
        let integer = val.split('.')[0]
        let decimal = val.split('.')[1]
        val = `${integer}.${decimal.slice(0, 2)}`
      }
      return val + 'B'
    }
    return value;
  }
};

export const tickerFilter = (str, maxLength) => {
  if (str?.length <= maxLength) {
    return str;
  }
  return str?.slice(0, maxLength) + "...";
};

export const bigNumberForBeautyFilterM = (value, isHandle, toFixedNumber = 2) => {
  if (value === 0 || value === '0') {
    return 0;
  }
  if (isNaN(Number(value)) || !value) {
    return '--';
  }
  if (document.documentElement.clientWidth > 992 && isHandle) {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: toFixedNumber,
    });
  }
  if (new BigNumber(value).comparedTo(new BigNumber(1000000)) === 1) {
    return (
      Number(
        new BigNumber(value).dividedBy(new BigNumber(1000000)).toString(),
      ).toLocaleString('en-US', { maximumFractionDigits: toFixedNumber }) + 'M'
    );
  } else {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: toFixedNumber,
    });
  }
};
