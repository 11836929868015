import * as bitcoin from "@/libs/bitcoinjs-lib";
import { baseBlockstreamApiUrl, ordinalsExplorerUrl } from "@/common/config";

const txHexByIdCache = {};
const fromBase58Check = bitcoin.address.fromBase58Check;
const fromBech32 = bitcoin.address.fromBech32;

export async function getTxHexById(txId) {
  if (!txHexByIdCache[txId]) {
    txHexByIdCache[txId] = await fetch(
      `${baseBlockstreamApiUrl}/tx/${txId}/hex`
    ).then((response) => response.text());
  }

  return txHexByIdCache[txId];
}

export function toOutput(inscriptionId) {
  let lastIndexOfI = inscriptionId.lastIndexOf("i");
  if (lastIndexOfI !== -1) {
    let output =
      inscriptionId.substring(0, lastIndexOfI) +
      ":" +
      inscriptionId.slice(lastIndexOfI + 1);
    return output;
  } else {
    return "";
  }
}

export function calculateFee(
  vins,
  vouts,
  recommendedFeeRate,
  address,
  includeChangeOutput = true
) {
  const baseTxSize = 10;
  const inSize = 180;
  const outSize = 34;

  const txSize =
    baseTxSize +
    vins * inSize +
    vouts * outSize +
    includeChangeOutput * outSize;
  let fee = txSize * recommendedFeeRate;
  if (isNativeSegwitAddress(address)) {
    fee = parseInt(fee / 1.7);
  }
  if (isNestedSegwitAddress(address)) {
    fee = parseInt(fee / 1.5);
  }
  if (isTaprootAddress(address)) {
    fee = parseInt(fee / 1.5);
  }
  if (isLegacyAddress(address)) {
    return fee;
  }

  return fee;
}

export function calculateFeeNew(
  vins,
  vouts,
  recommendedFeeRate,
  address,
  includeChangeOutput = true
) {
  // tb1...
  // const baseTxSize = 2;
  // const inSize = 66;
  // const outSize = 43;

  // mn...
  // const baseTxSize = 45;
  // const inSize = 108;
  // const outSize = 46;

  // 2...
  // const baseTxSize = 2;
  // const inSize = 90;
  // const outSize = 45;

  let baseTxSize = 0;
  let inSize = 0;
  let outSize = 0;

  if (isNativeSegwitAddress(address)) {
    baseTxSize = 2;
    inSize = 66;
    outSize = 43;
    if (Number(recommendedFeeRate) < 4) {
      recommendedFeeRate = Number(recommendedFeeRate) + 0.2;
    }
  }
  if (isNestedSegwitAddress(address)) {
    baseTxSize = 2;
    inSize = 90;
    outSize = 45;
  }
  if (isTaprootAddress(address)) {
    baseTxSize = 2;
    inSize = 66;
    outSize = 43;
    if (Number(recommendedFeeRate) < 4) {
      recommendedFeeRate = Number(recommendedFeeRate) + 0.2;
    }
  }
  if (isLegacyAddress(address)) {
    // normal
    baseTxSize = 2;
    inSize = 73;
    outSize = 42;
    // case testnet n...
    if (address.startsWith("n")) {
      baseTxSize = 2;
      inSize = 73;
      outSize = 42;
    }
    // case testnet m...
    if (address.startsWith("m")) {
      baseTxSize = 2;
      inSize = 174;
      outSize = 43;
    }
    // case mainnet 1...
    if (address.startsWith("1")) {
      baseTxSize = 62;
      inSize = 113;
      outSize = 43;
    }
    // case min relay n...
    if (Number(recommendedFeeRate) < 4 && address.startsWith("n")) {
      recommendedFeeRate = Number(recommendedFeeRate) * 1.5;
    }
    // case min relay m...
    if (Number(recommendedFeeRate) < 4 && address.startsWith("m")) {
      recommendedFeeRate = Number(recommendedFeeRate) * 1;
    }
    // case min relay 1...
    if (Number(recommendedFeeRate) < 7 && address.startsWith("1")) {
      recommendedFeeRate = Number(recommendedFeeRate) * 1.2;
    }
  }

  const txSize =
    baseTxSize +
    vins * inSize +
    vouts * outSize +
    includeChangeOutput * outSize;
  let fee = txSize * recommendedFeeRate;
  return fee;
}

export function base64ToHex(str) {
  return atob(str)
    .split("")
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
    .join("");
}

export async function getAddressUtxos(address) {
  return await fetch(`${baseBlockstreamApiUrl}/address/${address}/utxo`).then(
    (response) => response.json()
  );
}

export async function doesUtxoContainInscription(utxo) {
  try {
    const res = await fetch(
      `${ordinalsExplorerUrl}/output/${utxo.txid}:${utxo.vout}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error(error.message);
      });
      if ((res.inscriptions && res.inscriptions.length > 0) || (res.runes && Object.keys(res.runes).length !== 0)) {
        return true
      } else {
        return false
      }
  } catch (error) {
    console.error(error.message);
    throw new Error("The Ordinals server encountered an exception.");
  }
}

export function isNativeSegwitAddress(address) {
  try {
    if (address.indexOf("bc1q") === 0 || address.indexOf("tb1q") === 0) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function isNestedSegwitAddress(address) {
  try {
    if (address.indexOf("3") === 0 || address.indexOf("2") === 0) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function isP2SHAddress(bitcoin, address, network) {
  try {
    const { version, hash } = bitcoin.address.fromBase58Check(address);
    return version === network.scriptHash && hash.length === 20;
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function isTaprootAddress(address) {
  try {
    if (address.indexOf("bc1p") === 0 || address.indexOf("tb1p") === 0) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function isLegacyAddress(address) {
  try {
    if (
      address.indexOf("1") === 0 ||
      address.indexOf("m") === 0 ||
      address.indexOf("n") === 0
    ) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export function getAddressType(address, network) {
  let decodeBase58;
  let decodeBech32;
  try {
    decodeBase58 = fromBase58Check(address);
  } catch (e) {}

  if (decodeBase58) {
    if (decodeBase58.version === network.pubKeyHash) return "legacy";
    if (decodeBase58.version === network.scriptHash) return "segwit_nested";
  } else {
    try {
      decodeBech32 = fromBech32(address);
    } catch (e) {}

    if (decodeBech32) {
      if (decodeBech32.prefix !== network.bech32)
        throw new Error(address + " has an invalid prefix");
      if (decodeBech32.version === 0) {
        return "segwit_native";
      } else if (decodeBech32.version === 1) {
        return "segwit_taproot";
      }
    }
  }
  return "legacy";
}

export const toXOnly = (pubKey) =>
  pubKey.length === 32 ? pubKey : pubKey.slice(1, 33);
