import request from "@/common/request";

export function getHomeData(params) {
  return request({
    url: "/brc100/application",
    method: "get",
    params,
  });
}

export function getLpData(params) {
  return request({
    url: "/brc100/application",
    method: "get",
    params,
  });
}

export function getTokenList(params) {
  return request({
    url: '/brc102/tokens',
    method: 'get',
    params,
  });
}

export function getLPChartData(params) {
  return request({
    url: '/brc102/data/lp',
    method: 'get',
    params,
  });
}

export function getVolumeChartData(params) {
  return request({
    url: '/brc102/data/token',
    method: 'get',
    params,
  });
}

export function getPriceChartData(params) {
  return request({
    url: '/brc102/data/token/price',
    method: 'get',
    params,
  });
}