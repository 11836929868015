import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/report/index"),
    hidden: true,
  },
  // {
  //   path: '/home',
  //   component: () => import('@/views/home/index'),
  //   hidden: true,
  // },
  {
    path: "/swap",
    component: () => import("@/views/swap/index"),
    hidden: true,
  },
  {
    path: "/pools",
    component: () => import("@/views/pools/index"),
    hidden: true,
  },
  {
    path: "/pool",
    component: () => import("@/views/detail/poolDetail.vue"),
    hidden: true,
  },
  {
    path: "/token",
    component: () => import("@/views/detail/tokenDetail.vue"),
    hidden: true,
  },
  // {
  //   path: "/farming",
  //   component: () => import("@/views/farming/index"),
  //   hidden: true,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
