import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.less';
import '@/styles/element-ui.less';
import '@/styles/reset.less';

import VueClipboard from 'vue-clipboard2';
import * as filters from './common/filters';
import { getTokenImgFromApplication } from './common/utils';

global.Buffer = global.Buffer || require('buffer').Buffer

for (let key in filters) {
  Vue.filter(key, filters[key]);
}

Vue.config.productionTip = false;
Vue.prototype.$getTokenImgFromApplication = getTokenImgFromApplication;

import dayjs from 'dayjs';
Vue.prototype.$dayjs = dayjs;

import TokenLogo from '@/components/TokenLogo';
Vue.component('TokenLogo', TokenLogo);

Vue.use(ElementUI);
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
