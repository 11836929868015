import { getAddress, signTransaction, BitcoinNetworkType } from "sats-connect";
import { isProduction } from "./config";

export default {
  async connect() {
    return new Promise((resolve, reject) => {
      const getAddressOptions = {
        payload: {
          purposes: ["ordinals", "payment"],
          message: "Connect to 100Swap",
          network: {
            type: isProduction ? "Mainnet" : "Testnet",
          },
        },
        onFinish: (response) => {
          resolve(response);
        },
        onCancel: () => reject("Request canceled"),
      };

      getAddress(getAddressOptions).catch((error) => {
        reject(error.message);
      });
    });
  },

  accountsChanged() {},

  async signPsbt(base64Psbt, inputsToSign = []) {
    return new Promise((resolve, reject) => {
      const signPsbtOptions = {
        payload: {
          network: {
            type: isProduction ? "Mainnet" : "Testnet",
          },
          psbtBase64: base64Psbt,
          broadcast: false,
          inputsToSign: inputsToSign,
        },
        onFinish: (response) => {
          resolve(response.psbtBase64);
        },
        onCancel: () => reject("Canceled"),
      };
      signTransaction(signPsbtOptions);
    });
  },
};
