export default {
  SET_BTC_PRICE(state, data) {
    state.btcPrice = data;
  },

  SET_BRC100_PRICE(state, data) {
    state.brc100Price = data;
  },

  SET_USER_ADDRESS(state, data) {
    state.userAddress = data;
  },

  SET_USER_PUBLIC_KEY(state, data) {
    state.userPublicKey = data;
  },
  SET_FULL_LOADING(state, data) {
    state.fullLoading = data;
  },
};
