import axios from "axios";
import { baseApiUrl } from "./config";
import LocalStorageManager from "@/common/storage";

const service = axios.create({
  baseURL: baseApiUrl,
});

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
    // if (res.code !== 200) {
    //   return Promise.reject(new Error(res.message || "Error"));
    // } else {
    //   return res;
    // }
  },
  (error) => {
    console.log("err" + error);
    return Promise.reject(error);
  }
);

service.interceptors.request.use(
  (config) => {
    if (config.baseURL === baseApiUrl) {
      const user = LocalStorageManager.getItem("100SWAP_CONNECTED_ADDRESS");
      if (user && user !== "") {
        config.headers.user = user;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
