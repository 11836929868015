import { isProduction } from "./config";
import LocalStorageManager from "./storage";
import { Notification } from "element-ui";
import * as bitcoin from "@/libs/bitcoinjs-lib";
import xverse from "./xverse-adapter";
import { base64ToHex } from "@/common/tools"

export const WALLET_ENUM = {
  OKX_WALLET: "okx_wallet",
  UNISAT_WALLET: "unisat_wallet",
  XVERSE_WALLET: "xverse_wallet",
};

export default {
  hasConnectedWallet() {
    return LocalStorageManager.getItem("100SWAP_CONNECT_WALLET");
  },

  async connect(type) {
    if (type === WALLET_ENUM.OKX_WALLET) {
      let res = isProduction
        ? await window.okxwallet.bitcoin.connect()
        : await window.okxwallet.bitcoinTestnet.connect();
      return [res.address, res.publicKey];
    }

    if (type === WALLET_ENUM.UNISAT_WALLET) {
      let accounts = await window.unisat.requestAccounts();
      let publicKey = await window.unisat.getPublicKey();
      return [accounts[0], publicKey];
    }

    if (type === WALLET_ENUM.XVERSE_WALLET) {
      let res = await xverse.connect();
      if (res.addresses.length > 0 && res.addresses[0].address) {
        return [res.addresses[0].address, res.addresses[0].publicKey];
      }
    }

    if (!type) {
      if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
        let res = isProduction
          ? await okxwallet.bitcoin.connect()
          : await okxwallet.bitcoinTestnet.connect();
        return [res.address, res.publicKey];
      }

      if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
        if (window.unisat) {
          let accounts = await window.unisat.requestAccounts();
          let publicKey = await window.unisat.getPublicKey();
          let network = await window.unisat.getNetwork();
          return [accounts[0], publicKey];
        }
      }

      if (this.hasConnectedWallet() === WALLET_ENUM.XVERSE_WALLET) {
        let address = LocalStorageManager.getItem("100SWAP_XVERSE_USER_ADDRESS");
        let publicKey = LocalStorageManager.getItem("100SWAP_XVERSE_USER_PUBLIC_KEY");
        return [address, publicKey];
      }
    }
  },

  accountsChanged() {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
      okxwallet.on("accountsChanged", () => {
        location.reload();
      });
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
      unisat.on("accountsChanged", () => {
        location.reload();
      });
    }
  },

  async disconnect() {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
      return isProduction
        ? await okxwallet.bitcoin.disconnect()
        : await okxwallet.bitcoinTestnet.disconnect();
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
    }
  },

  async getNetwork() {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
    }
  },

  async getAddress() {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
      return isProduction
        ? await okxwallet.bitcoin.connect().address
        : await okxwallet.bitcoinTestnet.connect().address;
    }
    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
      return (await unisat.requestAccounts())[0];
    }
  },

  async sendInscription(userAddress, receiveAddress, inscriptionId, feeRate) {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
      let res = await window.okxwallet.bitcoin.transferNft({
        from: userAddress,
        to: receiveAddress,
        data: inscriptionId,
      });
      return res.txhash;
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
      if (!feeRate) {
        feeRate = 150;
        console.warn("use default feeRate", feeRate);
      }
      let res = await window.unisat.sendInscription(
        receiveAddress,
        inscriptionId,
        {
          feeRate: feeRate,
        }
      );
      return res;
    }
  },

  async signPsbt(base64Psbt, address, type, inputsToSign = []) {
    if (this.hasConnectedWallet() === WALLET_ENUM.OKX_WALLET) {
      const param = type ? { from: address, type: type } : { from: address };
      let signedPsbtHex = isProduction
        ? await window.okxwallet.bitcoin.signPsbt(base64Psbt, param)
        : await window.okxwallet.bitcoinTestnet.signPsbt(base64Psbt, param);
      // the inputs signed by okx-wallet should be finalized
      let signedPsbt = bitcoin.Psbt.fromHex(signedPsbtHex);
      signedPsbt.data.inputs.forEach((input, index) => {
        // If it's already signed, there's no need to sign again
        if (!input.finalScriptWitness && !input.finalScriptSig) {
          signedPsbt.finalizeInput(index);
        }
      });
      return signedPsbt.toHex();
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.UNISAT_WALLET) {
      return await window.unisat.signPsbt(base64Psbt);
    }

    if (this.hasConnectedWallet() === WALLET_ENUM.XVERSE_WALLET) {
      let signedBase64Psbt = await xverse.signPsbt(
        bitcoin.Psbt.fromHex(base64Psbt).toBase64(),
        inputsToSign
      );
      let signedHexPsbt = base64ToHex(signedBase64Psbt);
      let signedPsbt = bitcoin.Psbt.fromHex(signedHexPsbt);
      signedPsbt.data.inputs.forEach((input, index) => {
        // If it's already signed, there's no need to sign again
        if (!input.finalScriptWitness && !input.finalScriptSig) {
          signedPsbt.finalizeInput(index);
        }
      });
      return signedPsbt.toHex();
    }
  },
};
